import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import NotFoundView from "../views/NotFoundView.vue";

const routes = [
  {
    path: "/",
    name: "Root",
    beforeEnter: async (to, from, next) => {
      const isAuthenticated = await store.dispatch("auth/checkAuth");
      if (!isAuthenticated) {
        next("/login");
        return;
      }

      const isAdmin = store.getters["auth/isAdmin"];
      if (isAdmin) {
        next("/admin/dashboard");
      } else {
        next("/dashboard");
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/LoginView.vue"),
  },
  {
    path: "/set-password/:uid/:token",
    name: "SetPassword",
    component: () => import("../views/auth/ChangePasswordView.vue"),
    props: true,
  },
  {
    path: "/reset-password/:uid/:token",
    name: "ResetPassword",
    component: () => import("../views/auth/ChangePasswordView.vue"),
    props: true,
  },
  {
    path: "/reset-password-request",
    name: "PasswordResetRequest",
    component: () => import("../views/auth/PasswordResetRequestView.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import("../views/client/dashboard/ClientDashboardView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/client/account/AccountView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/bots",
    name: "Bots",
    component: () => import("../views/client/bots/BotView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/reports",
    name: "Reports",
    component: () => import("../views/client/reports/ReportView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      {
        path: "dashboard",
        name: "AdminDashboard",
        component: () =>
          import("../views/admin/dashboard/AdminDashboardView.vue"),
      },
      {
        path: "accounts",
        name: "AdminAccounts",
        component: () => import("../views/admin/accounts/ListView.vue"),
      },
      {
        path: "accounts/:id",
        component: () => import("../views/admin/accounts/DetailView.vue"),
        children: [
          {
            path: "",
            name: "AccountOverview",
            component: () => import("../views/admin/accounts/OverviewTab.vue"),
          },
          {
            path: "bots",
            name: "AccountBots",
            component: () => import("../views/admin/accounts/BotsTab.vue"),
          },
          {
            path: "keys",
            name: "AccountAPIKeys",
            component: () => import("../views/admin/accounts/APIKeysTab.vue"),
          },
          {
            path: "transfers",
            name: "AccountTransfers",
            component: () => import("../views/admin/accounts/TransfersTab.vue"),
          },
          {
            path: "portal-logins",
            name: "AccountPortalLogins",
            component: () =>
              import("../views/admin/accounts/PortalLoginsTab.vue"),
          },
        ],
      },
      {
        path: "backtests",
        name: "Backtests",
        component: () => import("../views/admin/backtests/ListView.vue"),
      },
      {
        path: "bots",
        name: "AdminBots",
        component: () => import("../views/admin/bots/ListView.vue"),
      },
      {
        path: "bots/:id",
        component: () => import("../views/admin/bots/DetailView.vue"),
        children: [
          {
            path: "",
            name: "AdminBotOverview",
            component: () => import("../views/admin/bots/OverviewTab.vue"),
          },
          {
            path: "position",
            name: "AdminBotPosition",
            component: () => import("../views/admin/bots/PositionTab.vue"),
          },
          {
            path: "active-orders",
            name: "AdminBotActiveOrders",
            component: () => import("../views/admin/bots/ActiveOrders.vue"),
          },
          {
            path: "buys",
            name: "AdminBotBuys",
            component: () => import("../views/admin/bots/BuysTab.vue"),
          },
          {
            path: "sells",
            name: "AdminBotSells",
            component: () => import("../views/admin/bots/SellsTab.vue"),
          },
          {
            path: "cancelled",
            name: "AdminBotCancelled",
            component: () => import("../views/admin/bots/CancelledTab.vue"),
          },
          {
            path: "rejected",
            name: "AdminBotRejected",
            component: () => import("../views/admin/bots/RejectedTab.vue"),
          },
          {
            path: "funding-fees",
            name: "AdminBotFundingFees",
            component: () => import("../views/admin/bots/FundingFeesTab.vue"),
          },
          {
            path: "audit-log",
            name: "AdminBotAuditLog",
            component: () => import("../views/admin/bots/AuditLogTab.vue"),
          },
          {
            path: "trading",
            name: "AdminBotTrading",
            component: () => import("../views/admin/bots/TradingTab.vue"),
          },
          {
            path: "returns",
            name: "AdminBotReturns",
            component: () => import("../views/admin/bots/ReturnsTab.vue"),
          },
          {
            path: "daily-returns",
            name: "AdminBotDailyReturns",
            component: () => import("../views/admin/bots/DailyReturnsTab.vue"),
          },
        ],
      },
      {
        path: "settings",
        component: () => import("../views/admin/settings/DetailView.vue"),
        children: [
          {
            path: "global-settings",
            name: "SettingsGlobal",
            component: () =>
              import("../views/admin/settings/GlobalSettingsTab.vue"),
          },
          {
            path: "global-actions",
            name: "SettingsGlobalActions",
            component: () =>
              import("../views/admin/settings/GlobalActionsTab.vue"),
          },
          {
            path: "profile-settings",
            name: "SettingsProfile",
            component: () =>
              import("../views/admin/settings/ProfileSettingsTab.vue"),
          },
          {
            path: "strategies",
            name: "SettingsStrategies",
            component: () =>
              import("../views/admin/settings/StrategiesTab.vue"),
          },
          {
            path: "reporting-periods",
            name: "SettingsReportingPeriods",
            component: () =>
              import("../views/admin/settings/ReportingPeriodsTab.vue"),
          },
        ],
      },
      {
        path: "keys/:id",
        component: () => import("../views/admin/apikeys/DetailView.vue"),
        children: [
          {
            path: "",
            name: "APIKeyOverview",
            component: () => import("../views/admin/apikeys/OverviewTab.vue"),
          },
          {
            path: "orders",
            name: "APIKeyOrders",
            component: () => import("../views/admin/apikeys/OrdersTab.vue"),
          },
          {
            path: "bulk-actions",
            name: "APIKeyBulkActions",
            component: () =>
              import("../views/admin/apikeys/BulkActionsTab.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isAuthenticated = await store.dispatch("auth/checkAuth");
    if (!isAuthenticated) {
      next("/login");
    } else if (to.matched.some((record) => record.meta.requiresAdmin)) {
      const isAdmin = store.getters["auth/isAdmin"];
      if (!isAdmin) {
        next("/dashboard");
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
