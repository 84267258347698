import axios from "axios";

export default {
  namespaced: true,

  state: {
    btcPrice: null,
    ethPrice: null,
    solPrice: null,
    xrpPrice: null,
    lastFetchTime: 0,
    CACHE_DURATION: 30000, // 30 seconds
    pollInterval: null,
  },

  getters: {
    // Maintain backward compatibility
    currentBTCPrice: (state) => state.btcPrice,

    // New getters for multi-currency support
    getPrice: (state) => (crypto) => {
      const priceMap = {
        btc: state.btcPrice,
        eth: state.ethPrice,
        sol: state.solPrice,
        xrp: state.xrpPrice,
      };
      return priceMap[crypto.toLowerCase()];
    },

    isPriceFresh: (state) =>
      Date.now() - state.lastFetchTime < state.CACHE_DURATION,
  },

  mutations: {
    SET_PRICES(state, prices) {
      state.btcPrice = prices.btc;
      state.ethPrice = prices.eth;
      state.solPrice = prices.sol;
      state.xrpPrice = prices.xrp;
      state.lastFetchTime = Date.now();
    },
    SET_POLL_INTERVAL(state, interval) {
      state.pollInterval = interval;
    },
  },

  actions: {
    async initializePrice({ dispatch }) {
      await dispatch("fetchPrices");
      dispatch("startPolling");
    },

    async fetchPrices({ commit, state, getters }) {
      if (getters.isPriceFresh) {
        return {
          btc: state.btcPrice,
          eth: state.ethPrice,
          sol: state.solPrice,
          xrp: state.xrpPrice,
        };
      }

      try {
        const response = await axios.get("/api/market/price/");

        if (response.data && !response.data.error) {
          commit("SET_PRICES", response.data);
          return response.data;
        }

        throw new Error("Invalid response from market data API");
      } catch (error) {
        console.error("Error fetching crypto prices:", error);
        return {
          btc: state.btcPrice,
          eth: state.ethPrice,
          sol: state.solPrice,
          xrp: state.xrpPrice,
        };
      }
    },

    startPolling({ commit, dispatch, state }) {
      if (state.pollInterval) {
        clearInterval(state.pollInterval);
      }

      const interval = setInterval(() => {
        dispatch("fetchPrices");
      }, state.CACHE_DURATION);

      commit("SET_POLL_INTERVAL", interval);
    },

    cleanup({ state }) {
      if (state.pollInterval) {
        clearInterval(state.pollInterval);
      }
    },
  },
};
