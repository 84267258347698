<template>
  <div id="app" class="min-h-screen bg-base-300 text-text-base">
    <AdminNavbar v-if="isAuthenticated && isAdminRoute" />
    <Navbar v-else-if="isAuthenticated" />
    <router-view v-if="authChecked" />

    <!-- Terms and Conditions Modal -->
    <TCModal
      v-if="showTermsModal"
      :customerName="username"
      @close="closeTermsModal"
      @accept="acceptTerms"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Navbar from "@/components/navbars/NavbarComponent.vue";
import AdminNavbar from "@/components/navbars/AdminNavbarComponent.vue";
import TCModal from "@/components/modals/TCModal.vue";
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "App",
  components: {
    Navbar,
    AdminNavbar,
    TCModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    // Only show modal when we explicitly know terms haven't been accepted
    const showTermsModal = computed(() => {
      const profileState = store.state.profile;
      return profileState.contractAcceptedAt === false;
    });

    const username = computed(() => store.state.profile.username);

    const isAdminRoute = computed(() => {
      return route.path.startsWith("/admin");
    });

    const isAuthenticated = computed(
      () => store.getters["auth/isAuthenticated"]
    );
    const authChecked = computed(() => store.getters["auth/authChecked"]);

    onMounted(async () => {
      await store.dispatch("auth/checkAuth");
      await store.dispatch("theme/initTheme");
      await store.dispatch("price/initializePrice");
    });

    const closeTermsModal = () => {
      // Allow closing if viewing terms
      if (store.state.profile.contractAcceptedAt) {
        store.commit("profile/setContractAccepted");
      }
    };

    const acceptTerms = async () => {
      await store.dispatch("profile/acceptTerms");
    };

    return {
      isAdminRoute,
      isAuthenticated,
      authChecked,
      showTermsModal,
      username,
      closeTermsModal,
      acceptTerms,
    };
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "authChecked"]),
  },
  watch: {
    isAuthenticated(newValue) {
      if (!newValue && this.$route.path !== "/login") {
        this.$router.push("/login");
      }
      // Show terms modal when user logs in if they haven't accepted
      if (newValue && !this.hasAcceptedContract) {
        this.showTermsModal = true;
      }
    },
  },
  methods: {
    ...mapActions("auth", ["checkAuth"]),
    ...mapActions("theme", ["initTheme"]),
  },
};
</script>

<style>
html {
  overflow-y: scroll;
}

body {
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#app {
  color: var(--text-base);
}
</style>
