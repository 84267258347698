import api from "@/api/axios";

const state = {
  username: null,
  email: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  preferredCurrency: null,
  preferredCommunicationMethod: null,
  isAccountOwner: false,
  isAccountManager: false,
  twoFactorEnabled: false,
  accountId: null,
  accountTier: null,
  contractAcceptedAt: true,
};

const getters = {
  fullName: (state) => {
    if (state.firstName && state.lastName) {
      return `${state.firstName} ${state.lastName}`;
    }
    return state.username;
  },
};

const actions = {
  async fetchProfile({ commit }) {
    try {
      const response = await api.get("/api/auth/user/");
      commit("setProfile", response.data);
    } catch (error) {
      console.error("Failed to fetch user profile:", error);
    }
  },
  async updateProfile({ commit }, profileData) {
    try {
      const response = await api.put("/api/auth/user/", profileData);
      commit("setProfile", response.data);
    } catch (error) {
      console.error("Failed to update user profile:", error);
      throw error;
    }
  },
  async acceptTerms({ commit }) {
    try {
      const response = await api.post("/api/auth/user/accept_terms/");
      commit("setContractAccepted", response.data.contract_accepted_at);
      return response.data;
    } catch (error) {
      console.error("Failed to accept terms:", error);
      throw error;
    }
  },
};

const mutations = {
  setProfile(state, profileData) {
    state.username = profileData.username;
    state.email = profileData.email;
    state.firstName = profileData.first_name;
    state.lastName = profileData.last_name;
    state.phoneNumber = profileData.phone_number;
    state.preferredCurrency = profileData.preferred_currency;
    state.preferredCommunicationMethod =
      profileData.preferred_communication_method;
    state.isAccountOwner = profileData.is_account_owner;
    state.isAccountManager = profileData.is_account_manager;
    state.twoFactorEnabled = profileData.two_factor_enabled;
    state.accountId = profileData.account_id;
    state.accountTier = profileData.account_tier;
    state.contractAcceptedAt = profileData.contract_accepted_at !== null;
  },
  setContractAccepted(state) {
    state.contractAcceptedAt = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
