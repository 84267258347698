<template>
  <BaseModal
    @close="preventClose"
    :showCloseButton="false"
    class="modal-xl"
    modalClass="relative max-w-3xl z-20 max-h-[90vh] flex flex-col"
  >
    <template #title>
      <div class="flex items-center justify-center w-full">
        <h3 class="text-2xl font-bold">
          {{
            contractAccepted ? "Terms of Service" : "Welcome to QuantM Alpha"
          }}
        </h3>
      </div>
    </template>

    <!-- Welcome Step -->
    <div
      v-if="!contractAccepted && currentStep === 'welcome'"
      class="modal-step"
    >
      <div class="modal-body px-8 py-6">
        <p class="text-base-content/80 mb-10 text-center max-w-2xl mx-auto">
          This platform is designed to provide you with unparalleled insights
          and control over your cryptocurrency trading bots. Before you dive in,
          let's highlight some key actions available to you.
        </p>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div class="border border-base-300 rounded-lg p-6">
            <h4 class="text-lg font-semibold mb-3 flex items-center">
              <ChartBarIcon class="h-5 w-5 mr-2" />
              Real-Time Analytics
            </h4>
            <p class="text-base-content/70">
              Access real-time data and analytics on your bots' performance,
              making it easier to make informed decisions quickly.
            </p>
          </div>
          <div class="border border-base-300 rounded-lg p-6">
            <h4 class="text-lg font-semibold mb-3 flex items-center">
              <CpuChipIcon class="h-5 w-5 mr-2" />
              Automated Trading
            </h4>
            <p class="text-base-content/70">
              Enjoy peace of mind with fully automated trading that operates
              24/7, ensuring you never miss a market opportunity.
            </p>
          </div>
        </div>

        <div class="mt-10 border border-base-300 rounded-lg p-6">
          <p class="text-base-content/70 text-center">
            Before you continue, please review and accept our Terms of Service.
            This is a crucial step to ensure you understand how our platform
            works.
          </p>
        </div>

        <div class="flex justify-center mt-10">
          <button class="btn btn-primary min-w-[200px]" @click="goToTerms">
            Review Terms of Service
          </button>
        </div>
      </div>
    </div>

    <!-- Terms Step -->
    <div v-else class="modal-step">
      <div class="modal-body px-8 py-6">
        <div class="max-h-[60vh] overflow-y-auto pr-4">
          <slot name="terms-content">
            <div class="space-y-6 text-base-content/70">
              <h4 class="text-xl font-semibold text-base-content mb-4">
                AUTOMATED TRADING SOFTWARE SERVICES AGREEMENT
              </h4>

              <p>
                THIS AGREEMENT ("Agreement") is entered into as of
                {{ formatDate(new Date()) }}, by and between QuantM Alpha Inc.,
                a Seychelles International Business Company located at 306
                Victoria House, Victoria, Mahe, Seychelles, Company №: 241377,
                hereinafter referred to as "Company" and/or "the Company" and/or
                "QuantM Alpha," and/or "we", and/or "our", and/or "us" and
                <strong>{{ customerName }}</strong
                >, an individual/entity, hereinafter referred to as "Customer",
                and/or "you", and/or "your".
              </p>

              <!-- Continue with all sections from the provided terms -->
              <section
                v-for="(section, index) in sections"
                :key="index"
                class="mb-8"
              >
                <h4 class="text-lg font-semibold text-base-content mb-3">
                  {{ section.title }}
                </h4>
                <div class="space-y-4" v-html="section.content"></div>
              </section>

              <!-- Appendices -->
              <section class="mb-8">
                <h4 class="text-lg font-semibold text-base-content mb-3">
                  Appendix A: Yield Distribution Table
                </h4>
                <div class="overflow-x-auto">
                  <table class="table table-compact w-full">
                    <thead>
                      <tr>
                        <th class="bg-base-200">Level</th>
                        <th class="bg-base-200">Minimum Investment</th>
                        <th class="bg-base-200">Customer's Profit %</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="level in vipLevels"
                        :key="level.name"
                        class="hover:bg-base-200"
                      >
                        <td class="border-b border-base-200">
                          {{ level.name }}
                        </td>
                        <td class="border-b border-base-200">
                          ${{ formatNumber(level.minInvestment) }}
                        </td>
                        <td class="border-b border-base-200">
                          {{ level.profitShare }}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>

              <!-- Appendix B and C -->
              <section class="mb-8">
                <h4 class="text-lg font-semibold text-base-content mb-3">
                  Appendix B: Estimating Liquidation Price
                </h4>
                <div class="space-y-4 text-base-content/70">
                  <p>
                    For example, if 1 Bitcoin is purchased at a market high
                    price of $50,000, and the Customer has chosen a Trading
                    Range of $10,000 (20% of the purchase price), the
                    Liquidation price would be calculated as follows:
                  </p>
                  <p>
                    Liquidation price = (49.5% of $50,000) - (24% of $10,000) =
                    $24,750 - $2,400 = $22,350
                  </p>
                  <p>
                    This means, should the market price of Bitcoin fall to
                    $22,350, it would trigger the Liquidation price based on the
                    automated trading system's calculations and the Customer's
                    specified Trading Range.
                  </p>
                </div>
              </section>

              <section class="mb-8">
                <h4 class="text-lg font-semibold text-base-content mb-3">
                  Appendix C: Trading Range Example
                </h4>
                <div class="space-y-4 text-base-content/70">
                  <p>
                    For Example, if the Customer sets the Trading Range at
                    $10,000 for a Bitcoin purchased at a high of $50,000, the
                    trading strategy will involve making incremental purchases
                    of Bitcoin as its market price declines, up to a lower
                    threshold of $40,000 (which is $10,000 less than the $50,000
                    highest purchase price). This approach allows for
                    capitalizing on potential price recoveries within this
                    specified range. The Trading Range thereby establishes a
                    trading range from $50,000 down to $40,000, directing the
                    automated trading system to execute purchases at various
                    price points within this interval to optimize the overall
                    investment value based on the Customer's risk tolerance.
                  </p>
                </div>
              </section>
            </div>
          </slot>
        </div>
        <div class="flex justify-center mt-8 pt-4 border-t border-base-300">
          <template v-if="!contractAccepted">
            <button
              class="btn btn-primary min-w-[240px]"
              :disabled="!hasReadTerms"
              @click="acceptTerms"
            >
              {{
                hasReadTerms
                  ? "Accept Terms & Continue"
                  : "Please read the terms..."
              }}
            </button>
          </template>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import BaseModal from "./BaseModal.vue";
import { ChartBarIcon, CpuChipIcon } from "@heroicons/vue/24/solid";
import { useStore } from "vuex";

export default defineComponent({
  name: "TCModal",
  components: {
    BaseModal,
    ChartBarIcon,
    CpuChipIcon,
  },
  props: {
    customerName: {
      type: String,
      default: "",
    },
  },
  emits: ["close", "accept"],
  setup(props, { emit }) {
    const store = useStore();
    const currentStep = ref("welcome");
    const hasReadTerms = ref(false);

    const contractAccepted = computed(() => {
      const profileState = store.state.profile;
      return profileState.contractAcceptedAt !== false;
    });

    const formatDate = (date) => {
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(date);
    };

    // Terms sections data
    const sections = [
      {
        title: "DEFINITIONS:",
        content: `
          <div class="space-y-4">
            <p><strong class="text-base-content">The Exchange:</strong> For the purposes of this Agreement, "The Exchange" or "ByBit" shall refer to the trading exchange where trading of the Customer's cryptocurrency will occur, www.ByBit.com. Company reserves the right to use other exchanges, and "The Exchange" will refer to whichever trading exchange is used by either Company or Customer.</p>
            
            <p><strong class="text-base-content">Supported Exchange(s):</strong> Refers to ByBit and any other cryptocurrency trading platforms that QuantM Alpha provides connection with and are chosen by the Customer for trading activities under this Agreement. This term encompasses all such platforms that the Customer may elect for executing trades, with the understanding that references to liquidation criteria, including the calculation of the Liquidation Price, are based on ByBit's current practices as detailed herein. For trading on other exchanges, the Customer is advised to refer to the specific liquidation documentation provided by those exchanges.</p>
            
            <p><strong class="text-base-content">Yield:</strong> For the purposes of this Agreement, "Yield" shall refer to the profit generated from the Company's automated software trading activities on behalf of the Customer.</p>
            
            <p><strong class="text-base-content">Trading Range:</strong> In this Agreement refers to the designated range within which Bitcoin is purchased, extending from the highest recorded purchase price down to a predetermined lower threshold. This range is determined by the Customer as a dollar amount below the highest purchase price, indicating the maximum potential decrease in purchase price the Customer is willing to accept for Bitcoin purchases before ceasing trading activities. The Trading Range guides the automated trading strategy in executing incremental purchases as the market price of Bitcoin decreases, aiming to capitalize on lower prices within this defined range. The specific parameters of the Trading Range are communicated by the Customer to the Company and are subject to adjustment based on the Customer's risk management strategy. Changes to the Trading Range, once some of the budget is used for purchasing within this range, will impact the calculation of the liquidation price. Increasing the Trading Range may have a positive effect by potentially lowering the liquidation price, whereas decreasing it may have a negative effect by potentially raising the liquidation price. Due to numerous factors and variables, the exact impact of these changes is difficult to estimate or calculate. The Customer accepts full responsibility for understanding and managing this aspect of their trading strategy. For an example of how the Trading Range operates, please refer to Appendix C.</p>
            
            <p><strong class="text-base-content">Liquidation Price:</strong> "Liquidation Price" in this Agreement shall mean the specific dollar amount that triggers the forced closure of a trader's position by the Supported Exchange(s) due to the Customer's margin balance falling below the required maintenance margin. This event might lead to the Customer incurring a total loss relative to the highest price at which the traded asset was purchased. The Liquidation Price is automatically calculated by the Supported Exchange(s), with the current methodology for ByBit outlined in Section 1.5 below. This calculation can be approximated by using 49.5% of the market high price at which the Company's automated trading system executed a purchase, minus 24% of the Trading Range specified by the Customer. The Liquidation Price dynamically adjusts to market conditions and the Customer's chosen Trading Range. While ByBit's exact calculations are currently referenced (see Appendix B for an estimated example), should other exchanges be utilized in the future, the Customer should consult the respective exchange's documentation for precise liquidation criteria. The Customer understands and accepts that changes to the Trading Range, especially after partial utilization of the allocated budget for purchases within this range, may impact the Liquidation Price. However, due to the complexity and variability of factors influencing these changes, precise estimation or calculation by the system is not currently feasible. The Customer assumes full responsibility for the implications of any adjustments made to the Trading Range.</p>
            
            <p><strong class="text-base-content">Funding Fees:</strong> Refers to the fees applied to positions in ByBit's Inverse Perpetual contracts. These fees are exchanged between traders based on the position they hold (long or short) and the relative position of the contract trading price against the spot price. Funding Fees serve to ensure that the perpetual contract's trading price remains anchored to the global spot price. The fees are determined periodically, and the specific amount depends on market conditions. Detailed explanations and the precise calculation method are available on ByBit's official documentation here: <a href="https://learn.bybit.com/bybit-guide/what-is-the-funding-fee/" class="text-primary hover:underline" target="_blank" rel="noopener noreferrer">https://learn.bybit.com/bybit-guide/what-is-the-funding-fee/</a> and <a href="https://www.bybit.com/en/help-center/article/Funding-fee-calculation#b" class="text-primary hover:underline" target="_blank" rel="noopener noreferrer">https://www.bybit.com/en/help-center/article/Funding-fee-calculation#b</a>.</p>
            
            <p><strong class="text-base-content">Digital Asset(s) or "Bitcoin":</strong> Refers to Bitcoin (BTC), Ethereum (ETH), and any other cryptocurrencies, tokens, or digital assets that are available for trading on the selected exchange(s) and chosen by the Customer for trading activities under this Agreement. The term 'Digital Asset(s)' replaces or can be used in place of 'Bitcoin' or 'BTC' throughout this Agreement to encompass a broader range of tradable assets as selected by the Customer.</p>
          </div>
        `,
      },
      {
        title: "1. SERVICES",
        content: `
          <p>1.1 Crypto Trading Services. Customer hereby engages QuantM Alpha to provide automated cryptocurrency trading software as a service, and QuantM Alpha agrees to accept such engagement, subject to the terms and conditions set forth herein.</p>
          <p>1.2 Deposit and Trading Pair. Customer agrees to deposit USDC (USD Coin) or USDT (Tether), hereinafter referred to as USDC/USDT, and/or Bitcoin, hereinafter referred to as BTC or Bitcoin, into Customer's own ByBit.com Custodial Unified Trading subaccount and enter the ByBit UID for Company, allowing Company's automated software application access to trade on Customer account via API access.</p>
          <p>1.3 Yield Generation, Distribution, and Fee Structure. The Company's automated software trading activities generate yield for the Customer, composed of USDC/USDT, Bitcoin, and/or other Digital Asset earnings. The Company charges a fee to the customer for the software's trading activities as a portion of the profits.</p>
          <p>1.4 Adjusting the Trading Range and Automatic Calculation of the Liquidation price. The Customer shall communicate their chosen Trading Range in US Dollar value to the Company electronically via messaging apps or email.</p>
          <p>1.5 Bitcoin Inverse Contract Liquidation and funding fees. Customer acknowledges and accepts the risk of the potential for liquidations of their Bitcoin if they choose to use the Company's automated trading software service for Bitcoin Inverse Perpetual and/or Inverse Futures contracts.</p>
          <p>1.6 Adjusting the Trading Range. The Customer may request adjustments to the Trading Range to reflect changes in their risk tolerance or trading strategy.</p>
          <p>1.7 Software Service Disclaimer. The Customer acknowledges that QuantM Alpha provides crypto trading services through software as a service (SaaS). While this software aims to automate the trading process, the Customer understands that it is subject to errors and malfunctions.</p>
        `,
      },
      {
        title: "2. TERMINATION",
        content: `
          <p>2.1 Customer Initiated Termination: The Customer may terminate this Agreement at any time. To do so, the Customer must disconnect their ByBit.com Custodial Unified Trading subaccount and notify the Company via email.</p>
          <p>2.2 Company Initiated Termination: The Company may terminate this Agreement and cease trading activities on behalf of the Customer at its discretion. In such an event, the Company will provide a 24-hour advance notice to the Customer via email.</p>
        `,
      },
      {
        title: "3. RISKS AND DISCLAIMERS",
        content: `
          <p>3.1 Past Performance Disclaimer. The Customer understands and acknowledges that past performance of QuantM Alpha's trading strategies and software is not indicative of future results.</p>
          <p>3.2 General Risks. The Customer understands and acknowledges that investing in, buying, and selling Digital Assets presents a variety of risks that are not presented by investing in, buying, and selling products in other, more traditional asset classes.</p>
          <ul class="list-disc pl-6 space-y-2 mt-2 text-base-content/70">
            <li>Digital Assets are not legal tender, operate without central authority or banks, and are not backed by any government.</li>
            <li>Digital Assets are a new technological innovation with a limited history and are a highly speculative asset class.</li>
            <li>Digital Assets could become subject to Forks, and various types of cyberattacks.</li>
            <li>Trading platforms may stop operating or shut down due to fraud, technical problems, hackers, or malware.</li>
            <li>The decentralized protocol could be affected by internet disruptions, fraud, or cybersecurity attacks.</li>
            <li>Regulatory actions or policies may limit the ability to exchange a Digital Asset.</li>
            <li>It may become illegal to acquire, own, sell, or use a Digital Asset in certain countries.</li>
            <li>A Digital Asset could decline in popularity, acceptance or use.</li>
            <li>Impermanent Loss risks in trading scenarios.</li>
            <li>Liquidation risks in cryptocurrency trading.</li>
            <li>ByBit Funding Fees Risk for Inverse Perpetual futures contracts.</li>
          </ul>
        `,
      },
      {
        title: "4. MISCELLANEOUS",
        content: `
          <p>4.1 Amendments. Any amendments or modifications to this Agreement must be made in writing and signed by both parties.</p>
          <p>4.2 Limitation of Liability: The Company shall not be liable for any loss or damage incurred by the Customer arising from the trading activities under this Agreement, except in cases of willful misconduct or gross negligence by the Company.</p>
          <p>4.3 Indemnification and Limitation of Liability. The Customer agrees to indemnify, defend, and hold harmless QuantM Alpha, its directors, officers, employees, agents, licensors, and suppliers (collectively, the 'Indemnified Parties') from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees and costs.</p>
          <p>4.4 Governing Law. This Agreement shall be governed by and construed in accordance with the laws of the Seychelles.</p>
          <p>4.5 Force Majeure. Neither party shall be liable to the other for any failure to perform any obligation under this Agreement due to causes beyond their reasonable control, including but not limited to acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.</p>
          <p>4.6 Dispute Resolution. In the event of a dispute arising under or in connection with this Agreement, the parties agree to first attempt to resolve the dispute through good faith negotiation.</p>
          <p>4.7 Jurisdictional Legality Disclaimer. The Customer acknowledges that cryptocurrency trading may not be legal in their home jurisdiction.</p>
          <p>4.8 Intellectual Property Rights. The Customer acknowledges that all intellectual property rights in the trading software and any documentation or materials provided in connection with the trading services are owned by QuantM Alpha or its licensors.</p>
          <p>4.9 Compliance with Laws, Regulations, and Prohibition of Illegal Activities: The Customer represents and warrants compliance with all applicable laws, regulations, sanctions, and not being located in, under control of, or a resident in any sanctioned countries or territories.</p>
          <p>4.10 Modifications to the Agreement. QuantM Alpha reserves the right to modify or amend this Agreement at any time for any reason, or for no reason at all, at QuantM Alpha's sole discretion.</p>
          <p>4.11 Agreement Revision and Acceptance: The Customer acknowledges that this Agreement is subject to regular reviews and revisions. Updated versions of the Agreement will be made available online within the Customer's dashboard account.</p>
          <p>4.12 Disclaimer of Warranties; Limitation of Liability: The Services are provided on an "as is" and "as available" basis without warranties of any kind, either express or implied.</p>
          <p>4.13 Intellectual Property Rights: All content present on QuantM Alpha's platform, including but not limited to texts, graphics, logos, icons, images, audio clips, digital downloads, and software, is the property of QuantM Alpha or its content suppliers and protected by international copyright and intellectual property laws.</p>
        `,
      },
      {
        title: "APPENDICES",
        content: `
          <h4 class="text-lg font-semibold text-base-content mb-3">Appendix A: Yield Distribution Table</h4>
          <div class="overflow-x-auto">
            <table class="table table-compact w-full">
              <thead>
                <tr>
                  <th>Level</th>
                  <th>Minimum Investment</th>
                  <th>Customer's Profit %</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>Standard</td><td>$50,000</td><td>60%</td></tr>
                <tr><td>VIP1</td><td>$100,000</td><td>61%</td></tr>
                <tr><td>VIP2</td><td>$200,000</td><td>62%</td></tr>
                <!-- ... continue with all VIP levels ... -->
                <tr><td>VIP20</td><td>$2,000,000+</td><td>80%</td></tr>
              </tbody>
            </table>
          </div>
          
          <h4 class="text-lg font-semibold text-base-content mb-3 mt-8">Appendix B: Estimating Liquidation Price</h4>
          <!-- ... rest of appendices ... -->
        `,
      },
    ];

    const close = () => {
      emit("close");
    };

    const goToTerms = () => {
      currentStep.value = "terms";
      // Add a small delay before allowing terms acceptance
      setTimeout(() => {
        hasReadTerms.value = true;
      }, 3000);
    };

    const acceptTerms = async () => {
      try {
        await store.dispatch("profile/acceptTerms");
        emit("accept");
        close();
      } catch (error) {
        console.error("Failed to accept terms:", error);
        // You might want to show an error message here
      }
    };

    const preventClose = () => {
      // Do nothing - prevent modal from closing
    };

    return {
      currentStep,
      hasReadTerms,
      contractAccepted,
      close,
      goToTerms,
      acceptTerms,
      formatDate,
      sections,
      preventClose,
    };
  },
});
</script>

<style scoped>
.modal-body {
  @apply text-base;
}

:deep(.modal-box) {
  width: 100%;
  max-width: 48rem;
  padding: 1.5rem 0;
}

/* Clean up scrollbar styling */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: theme("colors.base-300") transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: theme("colors.base-300");
  border-radius: 3px;
}

/* Remove animations */
.benefit,
.modal-step,
.shadow-lg {
  transform: none;
  transition: none;
}
</style>
