<template>
  <div class="drawer">
    <input
      id="my-drawer-3"
      ref="drawerToggle"
      type="checkbox"
      class="drawer-toggle"
    />
    <div class="drawer-content flex flex-col">
      <!-- Navbar -->
      <div ref="navbar" class="navbar">
        <div class="flex-none lg:hidden">
          <label for="my-drawer-3" class="btn btn-square btn-ghost">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="inline-block w-6 h-6 stroke-current"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </label>
        </div>
        <div class="flex-1 px-2 mx-2">
          <router-link to="/dashboard">
            <BrandComponent />
          </router-link>
        </div>
        <div class="flex-none hidden lg:block">
          <ul class="menu menu-horizontal">
            <li v-if="isAdmin">
              <router-link to="/admin/dashboard"> Admin </router-link>
            </li>
            <li>
              <router-link to="/dashboard"> Dashboard </router-link>
            </li>
            <li>
              <router-link to="/bots"> Bots </router-link>
            </li>
            <li>
              <router-link to="/account"> Account </router-link>
            </li>
            <li><a @click="handleLogout">Logout</a></li>
          </ul>
        </div>
      </div>

      <!-- Animated FAB for mobile -->
      <transition name="fade-slide">
        <label
          v-if="showFAB"
          for="my-drawer-3"
          class="btn btn-primary btn-circle fixed right-4 bottom-4 lg:hidden z-50 opacity-90 hover:opacity-100 shadow-lg transition-opacity duration-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="inline-block w-6 h-6 stroke-current"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </label>
      </transition>
    </div>
    <!-- Sidebar / Drawer -->
    <div class="drawer-side">
      <label for="my-drawer-3" class="drawer-overlay" />
      <div class="menu p-4 w-[300px] h-full bg-base-300 text-base-content">
        <div class="mb-4">
          <BrandComponent />
        </div>
        <ul class="space-y-2">
          <li>
            <router-link
              to="/dashboard"
              class="flex items-center p-2 rounded-lg hover:bg-base-200"
              @click="closeDrawer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                />
              </svg>
              Dashboard
            </router-link>
          </li>
          <li>
            <router-link
              to="/bots"
              class="flex items-center p-2 rounded-lg hover:bg-base-200"
              @click="closeDrawer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                  clip-rule="evenodd"
                />
              </svg>
              Bots
            </router-link>
          </li>
          <li>
            <router-link
              to="/account"
              class="flex items-center p-2 rounded-lg hover:bg-base-200"
              @click="closeDrawer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clip-rule="evenodd"
                />
              </svg>
              Account
            </router-link>
          </li>
          <!-- Add v-if directive to check if user is admin -->
          <li v-if="isAdmin">
            <router-link
              to="/admin/dashboard"
              class="flex items-center p-2 rounded-lg hover:bg-base-200"
              @click="closeDrawer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clip-rule="evenodd"
                />
              </svg>
              Admin
            </router-link>
          </li>
        </ul>
        <div class="mt-auto pt-4 border-t border-base-200">
          <a
            class="flex items-center p-2 rounded-lg hover:bg-base-200 text-error"
            @click="handleLogout"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                clip-rule="evenodd"
              />
            </svg>
            Logout
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BrandComponent from "@/components/BrandComponent.vue";

export default {
  name: "NavbarComponent",
  components: {
    BrandComponent,
  },
  data() {
    return {
      showFAB: false,
      lastScrollPosition: 0,
      navbarHeight: 0,
    };
  },
  computed: {
    ...mapGetters("theme", ["currentTheme"]),
    ...mapGetters("auth", ["isAuthenticated", "isAdmin"]),
  },
  mounted() {
    this.navbarHeight = this.$refs.navbar?.offsetHeight || 0;
    window.addEventListener("scroll", this.onScroll);
    this.checkAuthStatus();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    ...mapActions("auth", ["logout", "checkAuth"]),
    async handleLogout() {
      try {
        await this.logout();
        this.closeDrawer();
        // The router navigation is now handled in the store action
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
    async checkAuthStatus() {
      await this.checkAuth();
      if (!this.isAuthenticated) {
        this.$router.push("/login");
      }
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Show FAB when scrolled past navbar
      this.showFAB = currentScrollPosition > this.navbarHeight;
      this.lastScrollPosition = currentScrollPosition;
    },
    closeDrawer() {
      if (this.$refs.drawerToggle) {
        this.$refs.drawerToggle.checked = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

/* Updated styles */
.drawer-side {
  z-index: 999;
}

.drawer-overlay {
  z-index: 998;
}

.menu {
  z-index: 1000;
}

/* Add smooth transition for hover effects */
.menu a {
  transition: background-color 0.2s ease;
}
</style>
